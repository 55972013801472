<template>
  <section class="scene bg-home mt-5 mt-md-0">
    <div class="container my-auto align-items-center align-middle">
      <div class="row">
        <div class="col-12 col-lg-6 my-lg-auto order-lg-2">
          <!-- Image -->
          <theCard />
        </div>
        <div class="col-12 col-lg-6 my-lg-auto mt-5">
          <!-- Heading -->
          <h2 class="text-uppercase">De Giorgi's Card</h2>
          <h1 class="display-3">Accedi a un mondo di vantaggi.</h1>
          <a
            class="btn btn-large btn-light shadow text-center mt-3"
            @click="homeButton"
            >{{ buttonText }}</a
          >
        </div>
      </div>
      <div class="mt-5" style="margin-top: 7rem !important">
        <h5>Resa possibile grazie a</h5>
        <img src="@/assets/img/sponsors@2x.png" height="100" />
        <p class="mt-2">
          <i>Rooster - Martinucci - Baldieri - Bar Caylè - Silvio Palma</i>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { auth } from "../assets/js/firebase";
import router from "../router";
import theCard from "@/components/TheCard.vue";
export default {
  created() {
    auth.onAuthStateChanged((user) => {
      this.isUserLogged = user ? true : false;
    });
  },
  data() {
    return {
      isUserLogged: false,
    };
  },
  methods: {
    homeButton() {
      router.push(this.isUserLogged ? "/user" : "/login");
    },
  },
  computed: {
    buttonText() {
      return this.isUserLogged ? "Mostra la card" : "Iscriviti ora";
    },
  },
  components: {
    theCard,
  },
};
</script>
